<template>
  <div>
    <footer>
      <span>关于我们</span>
      <span class="company">成都壹石新科信息技术有限公司技术支持</span>
      <span>四川省成都市高新区天府软件园B区B7栋6层</span>
      <span>联系电话：4000838919</span>
    </footer>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  footer{
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    //min-width: 1200px;
    width: 100%;
    color: #fff;
    text-align: center;
    background-color: #242424;
    span:nth-child(3) {
      margin-right: 20px;
    }
    .company{
      margin: 0 20px;
    }
  }
  @media (max-width:768px) {
    footer{
      //min-height: 40px;
      line-height: 30px;
      font-size: 12px;
      // min-width: 1200px;
      width: 100%;
      color: #fff;
      text-align: center;
      background-color: #242424;
      span {
        display: block;
      }
      .company{
        margin: 0 5px;
      }
      span:nth-child(3) {
        margin-right: 5px;
      }
    }
  }
</style>